import {globalState} from "./mx-demo-vue.js";
import { initializeVideoForm } from "../marketo/form-app-initializer.js";
import { dynamicModalWrapper } from "../marketo/form-cta-modal-launcher.js";

const { launchModalForm } = await import("../marketo/form-modal-launcher.js");

// The Demo Form
$(".js-launchMxDemoForm").on("click", async function() {

	const widgetData = {
		widget: {
			name: "Demo Request Form",
			variation: "Mx Interactive Demo",
			instanceId: "",
		},
		setup: {
			type: "ContactForm",
			form: {
				name: "Demo Request Blade",
			},
			tracking: {
				usePageOfferId: false,
				offerId: "7013x000001yAzlAAE",
			}
		}
	};

	const { componentInstance } = await launchModalForm({
		formSettingsKey: "demo-request",
		widgetData: widgetData,
		isInModal: true,
		customData:{
			disableFormHeading: false,
			ctaButtonText: "Schedule Your Demo",
			formHeading: "Let's get your demo scheduled!",
		}
	});

	componentInstance.onEvent("completed", () => {
		globalState.value = true;
	});
});

// The Gated Video Form
$(".js-launchMobilePartialGate").on("click", async function() {

	const rootContainer = dynamicModalWrapper.create();
	const { appInstance, componentInstance} = await initializeVideoForm({
		rootContainer: rootContainer,
		formSettingsKey: "video-gated",
		isInModal: true,
    widget: {
            name: "Mx Demo Page",
            variation: "Mx Demo Mobile Video Form",
            instanceId: "Mx Demo Mobile Video Form"
    },
    legacyId: "536",
		customData:{
			disableFormHeading: false,
			ctaButtonText: "View Mx Overview",
		}
	});

	dynamicModalWrapper.cleanup(appInstance, componentInstance);
});